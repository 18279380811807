import { Box, Button, Card, CardBody, CardFooter, CardHeader, Center, FormControl, FormErrorMessage, FormLabel, Heading, IconButton, Input, InputGroup, InputLeftElement, InputRightElement, Select, Spinner, Stack, Text, Tooltip } from "@chakra-ui/react"
import { ArrowBackIcon, CopyIcon } from "@chakra-ui/icons"
import { useNavigate, useParams } from "react-router-dom"
import { apiADM } from "../../services/api_adm"
import { useEffect, useState } from "react"
import { useForm } from 'react-hook-form'
import validator from 'validator'
import { ToastChakra } from "../../helpers/toast"

export default function ClienteDetalhes() {
  const [loading, setLoading] = useState({
    data: false,
    button: false
  })
  const [contribuinte, setContribuinte] = useState(null)
  const [assinaturas, setAssinaturas] = useState([])
  const [assinaturaId, setAssinaturaId] = useState(1)
  const [valorAssinatura, setValorAssinatura] = useState(0)
  // const [fatura, setFatura] = useState(0)
  const [keyIsCopied, setKeyIsCopied] = useState(false)

  const { id } = useParams()
  const navigate = useNavigate()
  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm();

  const valor_revenda = watch("valor_revenda")

  const getById = async () => {
    setLoading(old => ({ ...old, data: true }))

    try {
      const res = await apiADM.get(`contribuinte/get/${id}`)
      const obj_contribuinte = res.data?.contribuinte[0]
      const obj_assinaturas = res.data?.assinaturas

      setContribuinte(obj_contribuinte)
      setAssinaturas(obj_assinaturas)
      setAssinaturaId(obj_contribuinte.id_assinatura ?? 1)
      // console.log(obj_contribuinte)

      setValue("valor_revenda", obj_contribuinte.valor_revenda ?? 0)
      setValue("chave_pix", obj_contribuinte.chave_pix)
      // setValue("keyclient_id", obj_contribuinte.keyclient_id)
      // setValue("keyclient_secret", obj_contribuinte.keyclient_secret)

      // const vFatura = obj_contribuinte.valor_fatura ?? obj_assinaturas[0].valor
      // setFatura(vFatura)

      const _assinaturaId = obj_contribuinte?.id_assinatura ?? 1

      const vAssinatura = (obj_assinaturas.find((item) => item.id === _assinaturaId)).valor
      setValorAssinatura(vAssinatura ?? obj_assinaturas[0].valor)
    } catch (err) {
      ToastChakra('Erro', "Erro ao carregar os dados", 'error', 3000, 'top-right')
      throw err
    } finally {
      setLoading(old => ({ ...old, data: false }))
    }
  }

  const habilitarContribuinte = async ({ valor_revenda, chave_pix }) => {
    setLoading(old => ({ ...old, button: true }))

    // const valor_assinatura = 0

    try {
      const res = await apiADM.post("/habilitacao/ativar", {
        id_assinatura: assinaturaId,
        id_contribuinte: Number(id) || 0,
        chave_pix: chave_pix,
        // keyclient_id: keyclient_id,
        // keyclient_secret: keyclient_secret,
        valor_revenda: valor_revenda,
        // valor_fatura: valor_revenda + valor_assinatura
      })
      // console.log(res.data)
      ToastChakra('Confirmação', res.data?.msg, 'success', 3000, 'top-right')

      if (res.data?.tokenCSC) {
        setContribuinte(old => ({ ...old, token_pass: res.data?.tokenCSC }))
      }
      // setFatura(getValorAssinatura(assinaturaId) + watch("valor_revenda"))
    } catch (err) {
      ToastChakra('Erro', err.response.data?.detail, 'error', 3000, 'top-right')
      // throw err
    } finally {
      setLoading(old => ({ ...old, button: false }))
    }
  }

  const getValorAssinatura = (id) => {
    return assinaturas.find((item) => item.id === id).valor
  }

  const handleChange = (e) => {
    // console.log(watch("valor_revenda"))
    setAssinaturaId(+e.target.value)

    // setFatura(0)
    const vAssinatura = getValorAssinatura(+e.target.value)
    // const valor_rev = Number(valor_revenda) || 0
    // setFatura(vAssinatura + valor_rev)
    setValorAssinatura(vAssinatura)
  }

  useEffect(() => {
    getById()
  }, [])

  return (
    <>
      {loading.data ? <Center minH="100vh"><Spinner /></Center> :
        <>
          <Card>
            <CardHeader>
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <IconButton
                  icon={<ArrowBackIcon fontSize="1.25rem" />}
                  title="Voltar"
                  onClick={() => navigate(-1)}
                />
                <Heading size='sm' mb={2}>{contribuinte?.fantasia}</Heading>
                <CardHeader color='blue.600' fontSize='md'>
                  {/* Valor total: R$ {contribuinte?.token_pass? fatura : Number(valor_revenda) + fatura} */}
                  Valor total: R$ {Number(valor_revenda) + valorAssinatura}
                </CardHeader>
              </Stack>
            </CardHeader>
          </Card>

          <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
            <Card>
              <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"space-between"}>
                <CardHeader>
                  <Heading size='sm'>{contribuinte?.token_pass ? "Dados do" : "Habilitar"} Cliente</Heading>
                </CardHeader>
              </Stack>

              <CardBody>
                <FormLabel>Assinatura</FormLabel>
                <Select
                  defaultValue={assinaturaId}
                  onChange={handleChange}
                >
                  {assinaturas?.map((item) => (
                    <option
                      key={item.id}
                      value={item.id}
                    >
                      {`${item.assinatura} (R$ ${item.valor})`}
                    </option>
                  ))}
                </Select>

                <FormControl mt={2} isInvalid={errors.valor_revenda ? true : false}>
                  <FormLabel>Adicional da Revenda</FormLabel>
                  <Input
                    type='number'
                    {...register('valor_revenda',
                      {
                        required: {
                          value: true,
                          message: "Valor é obrigatório.",
                        },
                        // validate: (value) => {
                        //   if (value <= 0) {
                        //     return "Dado inválido."
                        //   }
                        // }
                      }
                    )}
                  />
                  {errors?.valor_revenda && (
                    <FormErrorMessage>
                      <>{errors?.valor_revenda.message}</>
                    </FormErrorMessage>
                  )}
                </FormControl>

                <FormControl mt={2} isInvalid={errors.chave_pix ? true : false}>
                  <FormLabel>Chave PIX</FormLabel>
                  <Input
                    type='text'
                    {...register('chave_pix',
                      // {
                      //   required: {
                      //     value: true,
                      //     message: "Chave é obrigatória."
                      //   }
                      // }
                    )}
                  />
                  {errors?.chave_pix && (
                    <FormErrorMessage>
                      <>{errors?.chave_pix.message}</>
                    </FormErrorMessage>
                  )}
                </FormControl>

                {/* <FormControl mt={2} isInvalid={errors?.keyclient_secret ? true : false}>
                  <FormLabel>Key Client Secret</FormLabel>
                  <Input
                    type='text'
                    {...register('keyclient_secret',
                      // {
                      //   required: {
                      //     value: true,
                      //     message: "Chave é obrigatória."
                      //   }
                      // }
                    )}
                  />
                  {errors?.keyclient_secret && (
                    <FormErrorMessage>
                      <>{errors?.keyclient_secret.message}</>
                    </FormErrorMessage>
                  )}
                </FormControl> */}

                {contribuinte?.token_pass &&
                  <FormControl mt={2}>
                    <FormLabel>Chave de ativação</FormLabel>
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <Tooltip label={keyIsCopied ? "Chave copiada" : "Copiar chave"} placement='left' closeOnClick={false}>
                        <IconButton
                          size='sm'
                          icon={<CopyIcon />}
                          onClick={() => {
                            navigator.clipboard.writeText(contribuinte?.token_pass)
                            setKeyIsCopied(true)
                          }}
                          backgroundColor={keyIsCopied ? "blue.100" : "gray.300"}
                        />
                      </Tooltip>
                      <Text>{contribuinte?.token_pass}</Text>

                    </Stack>
                  </FormControl>}
              </CardBody>
              <CardFooter>
                <Button
                  onClick={() => handleSubmit(habilitarContribuinte)()}
                  isDisabled={loading.button}
                >
                  {loading.button ? <Spinner /> : contribuinte?.token_pass ? "Salvar" : "Habilitar"}
                </Button>
              </CardFooter>
            </Card>
          </Box>
        </>}
    </>
  );
}
