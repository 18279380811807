import { TbReportAnalytics, TbUsers, TbReportMoney, TbCloudUpload, Doc } from "react-icons/tb"
import { TiDocumentText } from "react-icons/ti"

export const dataNav = [
    {
        label: "Dashboard",
        path: "/dashboard",
        icon: TbReportAnalytics
    },
    {
        label: "Clientes",
        path: "/clientes",
        icon: TbUsers
    },
    {
        label: "Acertos",
        path: "/acertos/saldo",
        icon: TbReportMoney
    },
    {
        label: "Documentação",
        path: "/documentacao/habilitacao",
        icon: TiDocumentText
    }
]