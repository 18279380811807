import { Badge, Box, Card, HStack, Heading, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getUserLocalStorage } from "../../helpers/auth";
import { apiADM } from "../../services/api_adm";

export default function CreditosView() {
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)

    const { id_revenda } = getUserLocalStorage()

    const getCreditos = async () => {
        try {
            const res = await apiADM.get(`/acerto/creditos/${id_revenda}`)
            // console.log(res.data)
            setData(res.data?.cobrancas)
            setTotal(res.data?.soma_creditos)
        } catch (err) {
            throw err
        }
    }

    useEffect(() => {
        getCreditos()
    }, [])
    return (
        <>
            <Card mb={4}>
                <Stack direction={"row"} p={4} justifyItems={"center"} justifyContent={"space-between"}>
                    <Text>Créditos - Contas a receber</Text>
                    <Text>{data.length} Registros</Text>
                    <Badge
                        variant='outline'
                        colorScheme='blue'
                        fontSize={"sm"}
                    >
                        Total {`R$ ${total},00`}
                    </Badge>
                </Stack>
            </Card>
            
            <Box overflowX="auto">
                <TableContainer>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>CNPJ</Th>
                                <Th>Cidade</Th>
                                <Th>Quitação</Th>
                                <Th>Vencimento</Th>
                                <Th>Situação</Th>
                                <Th>Valor Doc</Th>
                            </Tr>
                        </Thead>

                        <Tbody style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                            {data?.map((item) => (
                                <Tr key={item.id}>
                                    <Td>
                                        <HStack spacing="3">
                                            <Box>
                                                <Text fontWeight="600">{item.fantasia}</Text>
                                                <Text fontSize={"small"}>{item.razao_social}</Text>
                                                <Text fontSize={'xs'} color="muted">{item.cnpj}</Text>
                                            </Box>
                                        </HStack>
                                    </Td>

                                    <Td>
                                        <Text color="muted">{item.cidade}-{item.uf}</Text>
                                    </Td>

                                    <Td>
                                        <Text color="muted">{item.quitacao}</Text>
                                    </Td>

                                    <Td>
                                        <Text color="muted">{item.vencimento}</Text>
                                    </Td>

                                    <Td>
                                        <Text color="muted">{item.situacao}</Text>
                                    </Td>

                                    <Td>
                                        <Text color="muted">{item.valor}</Text>
                                    </Td>

                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}