import {Avatar, AvatarGroup, Box, Center, DarkMode, Flex, Heading, HStack, Stack, Text, useBreakpointValue, useColorModeValue as mode,} from '@chakra-ui/react'
import * as React from 'react'
import { Outlet } from 'react-router-dom';
import { LogoRotta } from '../../components/logo';

export const AuthLayout = () => (
  <Flex
    minH={{
      base: 'auto',
      md: '100vh',
    }}
    bgGradient={useBreakpointValue({
      md: mode(
        'linear(to-r, blue.600 50%, white 50%)',
        'linear(to-r, blue.600 50%, gray.900 50%)',
      ),
    })}
  >

    
    <Flex maxW="8xl" mx="auto" width="full">
      <Box
        flex="1"
        display={{
          base: 'none',
          md: 'block',
        }}
      >
        <DarkMode>
          <Flex
            direction="column"
            px={{
              base: '4',
              md: '8',
            }}
            height="full"
            color="#CEEDFF"
          >
            <Flex align="center" h="24">
              <LogoRotta style={{ filter: "brightness(0.10) invert(100%)"}} />
            </Flex>
            <Flex flex="1" align="center">
              <Stack spacing="8">
                <Stack spacing="6">
                  <Heading
                    size={useBreakpointValue({
                      md: 'lg',
                      xl: 'xl',
                    })}
                  >
                    Integração do serviço PIX
                  </Heading>
                  <Text fontSize="lg" maxW="md" fontWeight="medium">
                    Aumente sua receita, habilitando seus clientes na integração do serviço pix.
                  </Text>
                </Stack>
                <HStack spacing="4">
                  <AvatarGroup
                    size="md"
                    max={useBreakpointValue({
                      base: 2,
                      lg: 5,
                    })}
                    borderColor="on-accent"
                  >
                    <Avatar name="Ryan Florence" src="https://bit.ly/ryan-florence" />
                    <Avatar name="Segun Adebayo" src="https://bit.ly/sage-adebayo" />
                    <Avatar name="Kent Dodds" src="https://bit.ly/kent-c-dodds" />
                    <Avatar name="Prosper Otemuyiwa" src="https://bit.ly/prosper-baba" />
                    <Avatar name="Christian Nwamba" src="https://bit.ly/code-beast" />
                  </AvatarGroup>
                  <Text fontWeight="medium">Revendas inscritas</Text>
                </HStack>
              </Stack>
            </Flex>
            <Flex align="center" h="24">
              <Text color="on-accent-subtle" fontSize="sm">
                © 2023 Rotta Tecnologia LTDA. All rights reserved.
              </Text>
            </Flex>
          </Flex>
        </DarkMode>
      </Box>


      <Center flex="1">

         {/* <Outlet  px={{ base: '4', md: '8' }}  py={{ base: '12', md: '48'}}  width="full" maxW="md" /> */}
         <Outlet/>

      </Center>
    </Flex>
  </Flex>
)