import { Box, Heading, Image, Link, ListItem, Text, UnorderedList } from "@chakra-ui/react";

export default function BancoDoBrasilView() {
    return (
        <>
            <Heading size={"sm"} mb={4}>PSP Banco do Brasil (Ambiente de Homologação)</Heading>
            <UnorderedList mb={4}>
                <ListItem>Cadastre-se no portal de desenvolvedores do Banco do Brasil:
                    <Link href="https://developers.bb.com.br/" target="_blank"> https://developers.bb.com.br/</Link>
                </ListItem>
                <ListItem>Após entrar no portal, crie uma Nova Aplicação, selecionando a API PIX, conforme
                    imagem abaixo:</ListItem>
            </UnorderedList>

            <Image
                src="/bb-nova-aplicacao-1.png"
                borderRadius={5}
                boxShadow={"outline"}
                mb={8}
                maxW={800}
            />
            <Image
                src="/bb-nova-aplicacao-2.png"
                borderRadius={5}
                boxShadow={"outline"}
                mb={8}
                maxW={800}
            />
            <Image
                src="/bb-nova-aplicacao-3.png"
                borderRadius={5}
                boxShadow={"outline"}
                mb={8}
                maxW={800}
            />
            <UnorderedList mb={8}>
                <ListItem>
                Entre na aplicação criada e selecione a opção "Credenciais" no menu à esquerda:
                </ListItem>
            </UnorderedList>
            <Image
                src="/bb-credenciais.png"
                borderRadius={5}
                boxShadow={"outline"}
                mb={8}
                maxW={800}
            />
        </>
    )
}