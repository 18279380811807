import { Badge, Box, Card, CardBody, CardHeader, Heading, Icon, SimpleGrid, Stack, StackDivider, Text } from "@chakra-ui/react";
import { FaUser } from "react-icons/fa";
import { FiTrendingDown, FiTrendingUp } from "react-icons/fi";

export default function DashboardView() {
    const data = [
        {
            id: 1,
            label: "Clientes",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
            icon: FaUser,
            count: 22345,
            percent: 59.3,
        },
        {
            id: 2,
            label: "Clientes ativos",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
            icon: FaUser,
            count: 32144,
            percent: 70.5,
        },
        {
            id: 3,
            label: "Clientes inativos",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit",
            icon: FaUser,
            count: 74231,
            percent: 27.4,
        }
    ]

    return (
        <Box>
            <Stack spacing="1" mb={4}>
              <Heading size={'sm'} fontWeight="medium">
                Painel da revenda
              </Heading>
              <Text>Dashboard</Text>
            </Stack>
            <Stack direction={"row"} spacing={2} alignItems={"center"} justifyContent={"flex-start"}>
                {data.map((item, index) => (
                    <Card align={"left"} minW={"sm"} key={index}>
                        {/* <CardHeader>
                            <Text
                                as={"h6"}
                                size='xs'
                                color={"rgb(140, 140, 140)"}
                                fontSize={"0.875rem"}
                                fontFamily={"Public Sans, sans-serif"}
                                fontWeight={400}
                                margin={0}
                                lineHeight={1.57}
                            >
                                {item.label}
                            </Text>
                        </CardHeader> */}
                        <CardBody>
                            <Text
                                as={"h6"}
                                size='xs'
                                color={"rgb(140, 140, 140)"}
                                fontSize={"0.875rem"}
                                fontFamily={"Public Sans, sans-serif"}
                                fontWeight={400}
                                margin={0}
                                lineHeight={1.57}
                            >
                                {item.label}
                            </Text>
                            <Stack direction={"row"} spacing={2} alignItems={"center"} mb={4}>
                                <Text
                                    as={"h4"}
                                    fontSize={"1.25rem"}
                                    fontWeight={600}
                                    lineHeight={1.4}
                                    fontFamily={"Public Sans, sans-serif"}
                                    color={"inherit"}
                                >
                                    {item.count}
                                </Text>
                                <Badge
                                    variant={"solid"}
                                    colorScheme={item.percent > 50 ? "blue" : "yellow"}
                                >
                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                        <Icon as={item.percent > 50 ? FiTrendingUp : FiTrendingDown} />
                                        <Text>{`${item.percent}%`}</Text>
                                    </Stack>
                                </Badge>
                            </Stack>
                            <Text
                                margin={0}
                                fontWeight={400}
                                fontSize={"0.75rem"}
                                lineHeight={1.66}
                                fontFamily={"Public Sans, sans-serif"}
                                color={"rgb(140, 140, 140)"}
                            >
                                {item.description}
                            </Text>
                        </CardBody>
                    </Card>
                ))}
            </Stack>
        </Box>
    )
}