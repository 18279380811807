import { Box, Card, Collapse, Container, Flex, Heading, Stack, Text, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

export default function DocumentacaoLayout() {
    const { isOpen, onToggle } = useDisclosure()
    const [idx, setIdx] = useState(0)
    const [subIdx, setSubIdx] = useState(0)
    const location = useLocation()
    // console.log(location.pathname)

    const SidebarContent = ({ onClose, ...rest }) => {

        const LinkItems = [
            {
                name: 'Habilitação',
                path: "habilitacao"
            },
            {
                name: "Sincronização",
                path: "sincronizacao"
            },
            {
                name: "Configuração",
                path: "configuracao"
            },
        ];

        const bancos = [
            {
                name: "Banco do brasil",
                path: "bb"
            }
        ]

        const NavItem = ({ to, children, ...rest }) => {
            return (
                <Link
                    to={to}
                    style={{
                        textDecoration: 'none',
                        fontWeight: "700",
                    }}
                    _focus={{ boxShadow: 'none' }}
                >
                    <Flex
                        align="center"
                        p="4"
                        mx="4"
                        borderRadius="lg"
                        role="group"
                        cursor="pointer"
                        _hover={{
                            bg: 'gray.100',
                            // color: 'white',
                        }}
                        {...rest}
                    >
                        {children}
                    </Flex>
                </Link>
            );
        };

        return (
            <Box
                bg={useColorModeValue('white', 'gray.900')}
                borderRight="1px"
                borderRightColor={useColorModeValue('gray.200', 'gray.700')}
                w={{ base: 'full', md: 60 }}
                // pos="fixed"
                // h="full"
                {...rest}
                borderRadius={10}
                minH={"100vh"}
            >
                <Box py={4}>
                    {LinkItems.map((link, index) => (
                        <NavItem
                            key={link.name}
                            to={link.path}
                            onClick={() => {
                                setIdx(index)
                            }}
                            // bg={idx === index ? "gray.100" : ""}
                            bg={location.pathname === `/documentacao/${link.path}` ? "gray.100" : ""}
                            mb={2}
                        >
                            {link.name}
                        </NavItem>
                    ))}
                    <NavItem
                        onClick={() => {
                            onToggle()
                            setIdx(-1)
                        }}
                        bg={idx === -1 ? "gray.100" : ""}
                    >
                        Bancos credenciados
                    </NavItem>
                    <Collapse in={isOpen} animateOpacity>
                        <Box pl='15px' mt={2}>
                            {bancos.map((item, index) => (
                                <NavItem
                                    key={item.path}
                                    to={item.path}
                                    onClick={() => {
                                        setIdx(-1)
                                        setSubIdx(index)
                                    }}
                                    bg={subIdx === index && idx === -1 ? "gray.100" : ""}
                                >
                                    Banco do Brasil
                                </NavItem>
                            ))}
                        </Box>
                    </Collapse>
                </Box>
            </Box>
        );
    };

    return (
        <>
            <Stack spacing="1" mb={4}>
                <Heading size={'sm'} fontWeight="medium">
                    Painel da revenda
                </Heading>
                <Text>Documentação</Text>
            </Stack>
            <Stack direction={"row"} spacing={4}>
                <SidebarContent />
                {/* <Box ml={{ base: 0, md: 60 }} p="4">
                <Outlet />
            </Box> */}
                <Card p={8}>
                    <Container>
                        <Outlet />
                    </Container>
                </Card>
            </Stack>
        </>
    )
}