import axios from "axios";
import { getTokenStorage } from '../services/serviceAuth'


// const token = getTokenStorage();

export const apiInsc = axios.create({
    baseURL: process.env.REACT_APP_API_INSC,
    // 'Content-Type': 'application/json',
    // Authorization: `Bearer ${token}`,
    // timeout: 10000,
});



apiInsc.interceptors.request.use(async (config) => {
    const token = getTokenStorage();
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
        return config
    } else {
        // config.headers= {'x-token': '$2b$12$igNK8cOQTaKh6yZpnw8LHuR/W8ZjZj3B9s.3tDj5mfMAlAzctF.cC'} 
        return config
    }
});