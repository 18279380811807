import { Box, Heading, Image, ListItem, Text, UnorderedList } from "@chakra-ui/react";

export default function ConfiguracaoView() {
    return (
        <>
            <Heading size={"sm"} mb={4}> Configurando o serviço de integração pix</Heading>
            <UnorderedList mb={4}>
                <ListItem>Clique em configurações</ListItem>
            </UnorderedList>
            <Image
                src="/configuracao-1.png"
                borderRadius={5}
                boxShadow={"outline"}
                mb={8}
            />
            <Heading size={"sm"} mb={4}>Configuração geral</Heading>
            <Box mb={8}>
                <UnorderedList mb={4}>
                    <ListItem>
                        Selecione o <strong>Banco do cliente</strong>
                    </ListItem>
                </UnorderedList>
            </Box>
            <Image
                src="/configuracao-2.png"
                borderRadius={5}
                boxShadow={"outline"}
                mb={8}
            />

            <Heading size={"sm"} mb={4}> Configuração de habilitação</Heading>
            <UnorderedList mb={4}>
                <ListItem>
                    Informe os dados do <strong>Banco e Chave de ativação</strong>
                </ListItem>
                <ListItem>
                    Se necessário informe o <strong>certificado digital e senha</strong>
                </ListItem>
                <ListItem>
                    Clique em <strong>salvar</strong>
                </ListItem>
                <ListItem>
                    Após salvar as configurações, aguarde alguns segundos para
                    visualizar o status da licença na tela principal.
                </ListItem>
            </UnorderedList>
            <Image
                src="/configuracao-3.png"
                borderRadius={5}
                boxShadow={"outline"}
                mb={8}
            />
        </>
    )
}