export const tabData = [
    {
        label: 'Habilitados',
        status: "habilitado",
    },
    {
        label: 'Ativos',
        status: 'ativo',
    },
    {
        label: 'Bloqueados',
        status: 'bloqueado',
    },
    {
        label: 'Todos',
        status: "",
    },
    {
        label: 'Não habilitados',
        status: 'nao habilitado',
    },
]