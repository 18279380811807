import { ArrowBackIcon } from "@chakra-ui/icons";
import { Button, ButtonGroup, Card, CardHeader, Heading, IconButton, Spacer, Stack, Text } from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getUserLocalStorage } from "../../helpers/auth";

export default function RepresentanteLayout() {
    const navigate = useNavigate()
    const { nome_usuario } = getUserLocalStorage()
    const location = useLocation()

    const data = [
        {
            title: "Saldo disponível",
            path: "/acertos/saldo"
        },
        {
            title: "Créditos",
            path: "/acertos/creditos"
        },
        {
            title: "Acertos",
            path: "/acertos/acertos"
        }
    ]

    return (
        <>
            <Stack spacing="1" mb={4}>
                <Heading size={'sm'} fontWeight="medium">
                    Painel da revenda
                </Heading>
                <Text>Acertos</Text>
            </Stack>
            <Card mb={4}>
                <CardHeader>
                    <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                        <IconButton
                            icon={<ArrowBackIcon fontSize="1.25rem" />}
                            title="Voltar"
                            onClick={() => navigate("/clientes")}
                        />
                        {/* <Heading size='sm'>{nome_usuario}</Heading> */}
                        <Stack spacing="4">
                            <ButtonGroup variant="ghost" spacing="1" >
                                {data.map((item) => (
                                    <Button
                                        fontSize={'15px'}
                                        aria-current={location.pathname === item.path ? "page" : ""}
                                        onClick={() => navigate(item.path)}
                                    >
                                        {item.title}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Stack>
                    </Stack>
                </CardHeader>
            </Card>
            <Outlet />
        </>
    )
}