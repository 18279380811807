import { Box, Heading, Image, ListItem, Text, UnorderedList } from "@chakra-ui/react";

export default function HabilitacaoView() {
    return (
        <>
            <Heading size={"sm"} mb={4}>Como habilitar o cliente</Heading>
            <UnorderedList mb={4}>
                <ListItem>Clique no botão Habilitar</ListItem>
            </UnorderedList>

            <Image
                src="/habilitacao-1.png"
                borderRadius={5}
                boxShadow={"outline"}
                mb={8}
            />
            <Heading size={"sm"} mb={4}>Habilitando o cliente</Heading>
            <UnorderedList mb={8}>
                <ListItem>
                    Selecione o plano de assinatura e insira o valor adicional da revenda.
                </ListItem>
                <ListItem>
                    Insira os dados do Banco recebedor do cliente e logo após clique em Habilitar.
                </ListItem>
                <ListItem>
                    Clique em
                    habilitar
                </ListItem>
                <ListItem>
                    Após habilitar o cliente copie a chave de ativação que aparecerá logo abaixo
                </ListItem>
            </UnorderedList>
            <Image
                src="/habilitacao-2.png"
                borderRadius={5}
                boxShadow={"outline"}
                mb={8}
            />
        </>
    )
}