import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react"

export const LandingPage = () => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    onOpen()
  }, [])

  return (
    <>
      {/* <Modal onClose={onClose} isOpen={isOpen} isCentered size={"6xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Image src="/rota-pub-1.jpg" />
          </ModalBody>
        </ModalContent>
      </Modal> */}

      <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>

        <Flex p={8} flex={1} align={'center'} justify={'center'}>
          <Stack spacing={6} w={'full'} maxW={'lg'}>
            <Heading fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
              <Text
                as={'span'}
                position={'relative'}
                _after={{
                  content: "''",
                  width: 'full',
                  height: useBreakpointValue({ base: '20%', md: '30%' }),
                  position: 'absolute',
                  bottom: 1,
                  left: 0,
                  bg: 'blue.400',
                  zIndex: -1,
                }}>
                Integração PIX
              </Text>
              <br />{' '}
              <Text color={'blue.400'} as={'span'}>
                Restrito para revendas
              </Text>{' '}
            </Heading>
            <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
              Este acesso é restrito para revendas da Hotline Tecnologia,
              para se inscrever, click no botão laranja abaixo,
              e use as credenciais que logra na Hotline.
            </Text>
            <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
              Se não é revenda,
              <Text>
                conheça o sistema hotline: <Link
                  color={"blue.300"}
                  href="https://www.hotlinetecnologia.com.br"
                  target="_blank"
                >
                  www.hotlinetecnologia.com.br
                </Link>
              </Text>
            </Text>
            <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
              <Button
                rounded={'full'}
                w="full"
                bg={'orange.400'}
                color={'white'}
                onClick={() => navigate("/insc/sign-in")}
                _hover={{
                  bg: 'orange.500',
                }}>
                Faça sua inscrição agora
              </Button>

            </Stack>

            <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
              <Button
                rounded={'full'}
                w="full"
                bg={'blue.400'}
                color={'white'}
                onClick={() => navigate("/auth/sign-in")}
                _hover={{
                  bg: 'blue.500',
                }}>
                Entrar
              </Button>

            </Stack>
          </Stack>
        </Flex>


        <Flex flex={1}>
          <Image
            alt={'Login Image'}
            objectFit={'cover'}
            src={
              'https://images.unsplash.com/photo-1527689368864-3a821dbccc34?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
            }
          />
        </Flex>


      </Stack>
    </>
  )
}