import {
  Box,
  Container,
  Stack,
  InputGroup,
  InputLeftElement,
  Input,
  Icon,
  Button,
  Heading,
  Spinner,
  Center,
  Text,
  Card,
  CardHeader,
  CardBody,
  Tabs,
  TabList,
  Tab,
  IconButton
} from '@chakra-ui/react'
import { FiSearch } from 'react-icons/fi'
import { ListagemClientes } from './listagem'
import { useEffect, useState } from 'react'
import { getUserLocalStorage } from '../../helpers/auth';
import { apiADM } from '../../services/api_adm';
import { useForm } from 'react-hook-form';
import { tabData } from './tab-data';
import { apiInsc } from '../../services/api_insc';
import { ToastChakra } from '../../helpers/toast';
import { FaSyncAlt } from 'react-icons/fa';

export const Clientes = () => {
  const [loading, setLoading] = useState(false)
  // const [fragment, setFragment] = useState("")
  const [data, setData] = useState([])
  const [status, setStatus] = useState("habilitado")

  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm();
  const { id_revenda } = getUserLocalStorage()

  const get_contribuinte = async (fragment) => {
    setLoading(true)

    try {
      const res = await apiADM.get("/contribuinte/get", {
        params: {
          id_revenda,
          fragment,
          status
        }
      })
      setData(res?.data?.contribuintes)
      // console.log(res?.data?.contribuintes)
    } catch (err) {
      throw err
    } finally {
      setLoading(false)
    }
  }

  const sincronizarContribuintes = async () => {
    setLoading(true)

    try {
      const res = await apiInsc.post("/contribuinte/get/news", {
        id_revenda
      })
      ToastChakra('Confirmação', res.data, 'success', 3000, 'top-right')
      get_contribuinte()
    } catch (err) {
      ToastChakra('Erro', "Erro ao sincronizar os dados", 'error', 3000, 'top-right')
      throw err
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = ({ fragment }) => {
    get_contribuinte(fragment)
  }

  useEffect(() => {
    get_contribuinte(null)
  }, [status])

  return (
    <>
      <Card mb={4}>
        <CardHeader>
          <Stack spacing="1" mb={4}>
            <Heading size={'sm'} fontWeight="medium">
              Painel da revenda
            </Heading>
            <Text>Clientes</Text>
          </Stack>
        </CardHeader>

        <CardBody>
          <Stack direction="row" spacing="3">
            <InputGroup maxW="xs">
              <InputLeftElement pointerEvents="none">
                <Icon as={FiSearch} color="muted" boxSize="5" />
              </InputLeftElement>
              <Input
                placeholder="Digite alguma coisa..."
                // onChange={(e) => setFragment(e.target.value)}
                {...register("fragment")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(onSubmit)()
                  }
                }}
                disabled={loading}
              />
            </InputGroup>

            <Button variant="primary" onClick={() => handleSubmit(onSubmit)()}>
              {loading ? <Spinner /> : "Pesquisar"}
            </Button>
          </Stack>

        </CardBody>

      </Card>

      <Card>

        <CardHeader>
          <Stack
            direction={{
              base: 'column',
              md: 'row',
            }}
            justify="space-between"
            alignItems={"center"}
          >
            <Text fontSize="lg" fontWeight="medium">
              Lista de clientes
            </Text>

            <Text fontSize="md" fontWeight="medium">
              {data.length} Registros
            </Text>

            <IconButton
              icon={loading ? <Spinner /> : <FaSyncAlt />}
              disabled={loading}
              onClick={sincronizarContribuintes}
            />

          </Stack>
        </CardHeader>

        <CardBody>
          <Tabs isManual variant='enclosed' mb={4}>
            <TabList>
              {tabData.map((tab, index) => (
                <Tab key={index} onClick={() => {
                  setStatus(tab.status)
                  // console.log(tab.status)
                }}>
                  {/* {loading ? tab.label : tab.status === status ? `${tab.label} (${data?.length})` : tab.label} */}
                  {tab.label}
                </Tab>
              ))}
            </TabList>
          </Tabs>

          {loading ? <Center minH={500}><Spinner /></Center> : <ListagemClientes data={data} />}

        </CardBody>
      </Card>
    </>
  );
};