import {
  Box,
  TableContainer,
  Text,
  Checkbox,
  HStack,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { HamburgerIcon, CheckCircleIcon } from "@chakra-ui/icons"
import { useNavigate } from 'react-router-dom'

export const ListagemClientes = ({ data }) => {
  const navigate = useNavigate()

  return (
    <Box overflowX="auto">
      <TableContainer>
        <Table>
          <Thead>
            <Tr>
              <Th>
                <HStack spacing="3">
                  <Checkbox />
                  <HStack spacing="1">
                    <Text>CNPJ</Text>
                    {/* <Icon as={FiArrowDown} color="muted" boxSize="4" /> */}
                  </HStack>
                </HStack>
              </Th>
              <Th>Cidade</Th>
              <Th>Contato</Th>
              <Th>Assinatura/Expira</Th>
              <Th>Situação</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>

          <Tbody style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
            {data?.map((cliente) => (
              <Tr key={cliente.id}>
                <Td>
                  <HStack spacing="3">
                    <Checkbox />
                    <Box>
                      <Text fontWeight="600">{cliente.fantasia}</Text>
                      <Text fontSize={"small"}>{cliente.razao_social}</Text>
                      <Text fontSize={'xs'} color="muted">{cliente.cnpj}</Text>
                    </Box>
                  </HStack>
                </Td>

                <Td>
                  <Text color="muted">{cliente.cidade}-{cliente.uf}</Text>
                </Td>

                <Td maxW={250}>
                  <HStack spacing="3">
                    <Box>
                      <Text fontWeight="medium">{cliente.contato}</Text>
                      <Text color="muted">{cliente.email}</Text>
                      <Text color="muted">{cliente.telefone}</Text>
                    </Box>
                  </HStack>
                </Td>

                <Td minW={200}>
                  <HStack spacing="3">
                    <Box>
                      <Text fontWeight="600">{cliente.assinatura}</Text>
                      <Text color="muted">{cliente.dt_expires_assin ? `Expira: ${cliente.dt_expires_assin}` : ""}</Text>
                    </Box>
                  </HStack>
                </Td>

                <Td>
                  {/* <Badge
                          size="sm"
                          colorScheme={!cliente.is_habilitado ? "gray.300" : cliente.vencido ? 'orange' : 'green'}
                        >
                          {!cliente.is_habilitado ? "Não habilitado" : cliente.vencido ? "Expirado" : "Ativo"}
                        </Badge> */}
                  <IconButton
                    icon={<CheckCircleIcon fontSize="1.25rem" />}
                    variant="ghost"
                    color={!cliente.is_habilitado ? "gray.300" : cliente.vencido ? 'orange' : 'green'}
                    title={!cliente.is_habilitado ? "Não habilitado" : cliente.vencido ? "Expirado" : "Ativo"}
                    _hover={{
                      // color: cliente.is_habilitado ? "gray.300" : "green",
                      cursor: !cliente.is_habilitado ? "not-allowed" : "default"
                    }}
                  />
                </Td>


                <Td>
                  <HStack spacing="1">
                    {/* <IconButton
                            icon={<CheckCircleIcon fontSize="1.25rem" />}
                            variant="ghost"
                            color={cliente.is_habilitado ? "gray.300" : "gray.700"}
                            title="Habilitar cliente"
                            _hover={{
                              color: cliente.is_habilitado ? "gray.300" : "green",
                              cursor: cliente.is_habilitado ? "not-allowed" : "default"
                            }}
                          /> */}
                    <IconButton
                      icon={<HamburgerIcon fontSize="1.25rem" />}
                      variant="ghost"
                      title="Detalhes do cliente"
                      onClick={() => navigate(`/clientes/${cliente.id}`)}
                    />
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}