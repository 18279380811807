import React from 'react';
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from './context/authcontext'
import { AppRouter } from './routes/routes'
import { theme } from './theme'



function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <AppRouter />
        </AuthProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
