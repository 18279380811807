import { 
  Avatar, 
  Box, 
  VStack,
  HStack, 
  MenuButton,
  MenuList,
  Menu,
  Text, 
  MenuItem, 
  useColorModeValue,
  MenuDivider, 
  Flex } from '@chakra-ui/react'

  import {FiChevronDown} from 'react-icons/fi';


export const UserProfile = (props) => { 
  const { username, image, icon, nivel, signout } = props
  
  return (
    <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}>
                
              <HStack>
                <Avatar
                  size={'sm'}
                  src={image}
                  icon={icon}
                />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text textTransform="capitalize" fontSize="sm">{username}</Text>
                  <Text fontSize="xs" color="gray.600">{nivel}</Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              fontSize="sm"
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              {/* <MenuItem>Seu perfil</MenuItem> */}
              {/* <MenuItem>Auditagem</MenuItem>
              <MenuItem>Ponto eletrônico</MenuItem> */}
              {/* <MenuDivider /> */}
              <MenuItem onClick={signout}>Sair</MenuItem>
             
            </MenuList>
          </Menu>
        </Flex>


    // <HStack spacing="3" ps="2">
    //   <Avatar name={username} src={image} boxSize="10" />
    //   <Box>
    //     <Text textTransform="capitalize" fontWeight="medium" fontSize="sm">
    //       {username}
    //     </Text>
    //     <Text color="muted" fontSize="smaller">
    //       {nivel}
    //     </Text>
    //   </Box>
    // </HStack>
  )
}