import { Heading, Image, ListItem, Text, UnorderedList } from "@chakra-ui/react";

export default function SincronizacaoView() {
    return (
        <>
            <Heading size={"sm"} mb={4}>Como sincronizar novos clientes</Heading>
            <UnorderedList mb={4}>
                <ListItem>Clique no botão sincronizar</ListItem>
            </UnorderedList>
            <Image
                src="/sincronizacao-1.png"
                borderRadius={5}
                boxShadow={"outline"}
                mb={8}
            />
            <Heading size={"sm"} mb={4}>Situação dos clientes</Heading>
            <Image
                src="/sincronizacao-2.png"
                borderRadius={5}
                boxShadow={"outline"}
                mb={8}
            />
        </>
    )
} 