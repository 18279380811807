import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { Sidebar } from '../sidebar/Sidebar'
import { ToggleButton } from './ToggleButton'
import { UserProfile } from '../profile/UserProfile'
import { useAuth } from '../../context/authcontext'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUserLocalStorage } from '../../helpers/auth'
import { FaUser } from "react-icons/fa"
import { LogoRotta } from '../logo'
import { useState } from 'react'
import { dataNav } from './data'

export const Navbar = () => {
  const [idx, setIdx] = useState(0)

  const { signout } = useAuth()
  const user = getUserLocalStorage()

  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })
  const { isOpen, onToggle, onClose } = useDisclosure()
  const navigate = useNavigate()

  const location = useLocation()

  // console.log(location.pathname.split("/")[1])

  return (
    <Box as="nav" bg="bg-surface" boxShadow="sm">
      <Container
        py={{
          base: '3',
          lg: '4',
        }}
      >
        <Flex justify="space-between">
          <HStack spacing="4">
            <LogoRotta />
            {isDesktop && (

              <ButtonGroup variant="ghost" spacing="1" >
                {dataNav.map((item, index) => (
                  <Button
                    key={item.path}
                    fontSize={'15px'}
                    // aria-current={idx === index ? "page" : ""}
                    aria-current={location.pathname.split("/")[1] === item.path.split("/")[1] ? "page" : ""}
                    onClick={() => {
                      navigate(item.path)
                      setIdx(index)
                    }}
                  >
                    {item.label}
                  </Button>
                ))}
              </ButtonGroup>
            )}
          </HStack>
          {isDesktop ? (
            <HStack spacing="12">
              <ButtonGroup variant="ghost" spacing="1">
                {/* <IconButton icon={<FiBell fontSize="1.25rem" />} aria-label="Notification" /> */}
                {/* <IconButton icon={<FiSettings fontSize="1.25rem" />} aria-label="Settings" /> */}

              </ButtonGroup>

              <UserProfile
                username={user?.nome_usuario}
                icon={<FaUser />}
                nivel={user?.grupo}
                signout={signout}
              />

            </HStack>
          ) : (
            <>
              <ToggleButton isOpen={isOpen} aria-label="Open Menu" onClick={onToggle} />
              <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                isFullHeight
                preserveScrollBarGap
                // Only disabled for showcase
                trapFocus={false}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <Sidebar onClose={onClose} user={user} signout={signout} location={location} navigate={navigate} />
                </DrawerContent>
              </Drawer>
            </>
          )}
        </Flex>
      </Container>
    </Box>
  )
}