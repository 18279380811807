import { Badge, Box, Card, Stack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getUserLocalStorage } from "../../helpers/auth";
import { apiADM } from "../../services/api_adm";

export default function SaldoView() {
    const [data, setData] = useState(null)

    const { id_revenda } = getUserLocalStorage()

    const getAcertos = async () => {
        try {
            const res = await apiADM.get(`/acerto/saldo/${id_revenda}`)
            // console.log(res.data)
            setData(res?.data)
        } catch (err) {
            throw err
        }
    }

    useEffect(() => {
        getAcertos()
    }, [])
    
    return (
        <>
        <Card mb={4}>
                <Stack direction={"row"} p={4} justifyItems={"center"} justifyContent={"space-between"}>
                    <Text>Royalties</Text>
                    <Badge
                        variant='outline'
                        colorScheme='blue'
                        fontSize={"sm"}
                    >
                        Total {`R$ ${data?.saldo},00`}
                    </Badge>
                </Stack>
            </Card>

            <Box overflowX="auto">
                <TableContainer>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th>Creditos</Th>
                                <Th>Débitos</Th>
                                <Th>Saldo</Th>
                            </Tr>
                        </Thead>

                        <Tbody style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                            <Tr>
                                <Td>
                                    <Text color="muted">{data?.creditos}</Text>
                                </Td>

                                <Td>
                                    <Text color="muted">{data?.acertos}</Text>
                                </Td>

                                <Td>
                                    <Text color="muted">{data?.saldo}</Text>
                                </Td>

                            </Tr>
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}