import React, { useEffect, useState } from "react"
import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons'
import { ToastChakra } from '../../helpers/toast'
import {
  Button,
  Container,
  FormControl,
  FormLabel,
  Divider,
  InputRightElement,
  HStack,
  Input,
  Stack,
  InputGroup,
  Text,
  Spinner,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useAuth } from "../../context/authcontext"
import { useNavigate } from "react-router-dom"
import { useForm } from 'react-hook-form'
import validator from 'validator'

export const SignInView = (props) => {
  const [loading, setLoading] = useState(false)
  // const [username, setUsername] = useState("")
  // const [password, setPassword] = useState("")
  const [showPassword, setShowPassword] = useState(false)

  const { register, handleSubmit, formState: { errors }, watch } = useForm();

  const { signin } = useAuth()
  const navigate = useNavigate()

  const onSubmit = async ({ email, password }) => {
    // e.preventDefault()

    setLoading(true)

    try {
      await signin(email, password)
    } catch (err) {
      console.log(err)
      ToastChakra(
        'Autenticação',
        err?.response?.data?.detail,
        'error',
        3000,
        'top-right'
      )
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const token = localStorage.getItem("accessToken")
    if (token) {
      navigate("/clientes")
    }
  }, [])


  return (

    <Container maxW="md" py={{ base: '12', md: '24' }}>
      <Stack direction={"column"} spacing="8">

        <Stack spacing="6">
          <Stack spacing={{ base: '2', md: '3' }} textAlign="center">
            <Text fontSize='4xl'>Login</Text>
            <Divider />
          </Stack>
        </Stack>

        <Stack spacing="6">
          <Stack spacing="5">
            <FormControl
              id="email"
              isInvalid={errors.email ? true : false}
              isRequired
              isDisabled={loading}
            >
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                placeholder="Entre com um email"
                {...register('email',
                  {
                    required: {
                      value: true,
                      message: "Email é obrigatório."
                    },
                    validate: (value) => {
                      if (!validator.isEmail(value)) {
                        return "Email é inválido."
                      }
                    }
                  }
                )}
              />
              {errors?.email && (
                <FormErrorMessage>
                  <>{errors?.email.message}</>
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              id="password"
              isInvalid={errors.password ? true : false}
              isRequired
              isDisabled={loading}
            >
              <FormLabel>Senha</FormLabel>
              <InputGroup as='form'>
                <Input
                  placeholder='informe a senha'
                  autoComplete='password'
                  type={showPassword ? 'text' : 'password'}
                  {...register('password',
                    {
                      required: {
                        value: true,
                        message: "Senha é obrigatório."
                      },
                      minLength: {
                        value: 4,
                        message: "Senha deve ser maior ou igual a 4 digitos."
                      }
                    })}
                  onKeyDown={(e) => {
                    if (e.code === "Enter" || e.code === "NumpadEnter") {
                      handleSubmit(onSubmit)();
                    }
                  }}
                />
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>

              {errors?.password && (
                <FormErrorMessage>
                  <>{errors?.password.message}</>
                </FormErrorMessage>
              )}
            </FormControl>
          </Stack>

          {/* <HStack justify="space-between">
                <Link to="/login/account">
                  <Button variant="link" colorScheme="blue" size="sm">
                    Esqueceu a senha?
                  </Button>                  
                </Link>
              </HStack> */}

        </Stack>

        <Button
          colorScheme={'blue'}
          variant={'solid'}
          onClick={() => handleSubmit(onSubmit)()}
          isDisabled={loading}
        >
          {loading ? <Spinner /> : "Entrar"}
        </Button>

        <Divider />

      </Stack>

    </Container>


  )
}
