import React, { useState} from "react";
import { LogoHotline } from '../../../components/logo'
import { GoogleIcon } from '../../../components/logo/ProviderIcons'
import { ServiceAuth } from '../../../services/serviceAuth';
import { ToastChakra } from '../../../helpers/toast';

import { 
  Button, 
  Container, 
  Divider, 
  Text, 
  Input, 
  Center,
  Stack, 
  FormControl,
  FormLabel } from '@chakra-ui/react'


export const Newpassword = (props) => {
  const auth = ServiceAuth();

  const [username, setUsername] = useState("");



  const onCickSubmit = async (e) => {    
    e.preventDefault()

    try {

      const response = await auth.serviceCoringas(username);

      if (!response) {
        ToastChakra('Autenticação', 'Credenciais inválida!', 'error', 3000, 'top-right');            
      } 

      
    } catch (error) {
      ToastChakra('Autenticação', 'Ocorreu um erro ao carregar coringas!!', 'error', 3000, 'top-right');
      
    }
  }  






  return (

    <Container
    maxW="md"
    py={{
      base: '12',
      md: '24',
    }}
  >
    <Stack spacing="8">
        <Stack spacing="6" align="center">
            <LogoHotline />
            <Center>
              <Text fontSize='4xl'>Recuperação de conta</Text>            
            </Center>
            <Divider /> 
        </Stack>
        <Stack spacing="6">
            <Button variant="secondary" leftIcon={<GoogleIcon boxSize="5" />} iconSpacing="3">
              Continue with Google
            </Button>

            <FormControl id="username" isRequired>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input  placeholder="Entre com um email" 
                      onChange={(e) => setUsername(e.target.value)}
                      type="email" />
            </FormControl>


        </Stack>

        <Center>
          <Text>Informe o email de recuperação da senha da conta</Text>
        </Center>
        
        <Stack spacing={6}>       
          <Button colorScheme={'blue'} variant={'solid'} onClick= {onCickSubmit} >
            Enviar
          </Button>
         
        </Stack>
        
                 
    </Stack>
  </Container>


  )
}
