import { Box, Container } from '@chakra-ui/react'
import { Navbar } from '../../components/navbar/Navbar'

export const AdminLayout = ({ children }) => {

  return (

    <Box as="section" height="100vh" overflowY="auto">
  
      <Navbar />
      <Container pt={{ base: '8', lg: '12'}} pb={{base: '12', lg: '24'}}>
        { children }
      </Container>

  </Box>

  )
}