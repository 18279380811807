import React from 'react';
import { Box, Button, Center, Container, Stack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

export const NotFoundPage = () => {

  return (
    <>
        <Container w={'full'}>
            <Center h={'100vh'} w={'full}'}>
              <Stack spacing={4} px={4} direction="column">
                <Box as="h1" fontSize="3xl" fontWeight={'bold'} textAlign="center">404 - Not Found</Box>
                {/* <Image src="https://media.giphy.com/media/3o7TKSjRrfIPjeNN3O/giphy.gif" alt="404 - Not Found" /> */}
                <Link to="/">        
                    <Button
                      rounded={'full'}
                      w="full"
                      bg={'blue.400'}
                      color={'white'}                      
                      _hover={{
                        bg: 'blue.500',
                      }}>
                        Home  
                    </Button>
                    
                </Link>
              </Stack>
            </Center>
        </Container>
    </>
  )
}