// import { useContext } from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { LandingPage } from '../views/LandingPage';
import { NotFoundPage } from '../views/404';
import { RequireAuth } from '../context/authrequired';
import { AdminLayout } from '../layout/admin';
import { routes } from './routesPrivate';


import { AuthLayout } from '../layout/auth';
import { Account } from '../views/auth/redefinir/Account'
import { Verifycode } from '../views/auth/redefinir/Verifycode'
import { Coringas } from '../views/auth/redefinir/Coringas'
import { Newpassword } from '../views/auth/redefinir/Newpassword'
import { SignInView } from '../views/auth/sign-in';
import { InscLayout } from '../layout/insc';
import { InscView } from '../views/insc';
import { RepresentanteLayout } from '../layout/representante';
import { AcertosView, CreditosView, SaldoView } from '../views/representante';
import { DocumentacaoLayout } from '../layout/documentacao';
import { BancoDoBrasilView, ConfiguracaoView, HabilitacaoView, SincronizacaoView } from '../views/documentacao';




export const AppRouter = () => {

    return (

        <Routes>

            <Route path="/" element={<LandingPage />} />


            <Route element={<MainRouterElement />}>

                {routes.map(({ path, element }) => (
                    <Route key={path} path={path} element={element} />
                ))}
                <Route path="/acertos" element={<RepresentanteLayout />}>
                    <Route path="saldo" element={<SaldoView />} />
                    <Route path="creditos" element={<CreditosView />} />
                    <Route path="acertos" element={<AcertosView />} />
                </Route>

                <Route path="/documentacao" element={<DocumentacaoLayout />}>
                    <Route path="habilitacao" element={<HabilitacaoView />} />
                    <Route path="sincronizacao" element={<SincronizacaoView />} />
                    <Route path="configuracao" element={<ConfiguracaoView />} />
                    <Route path="bb" element={<BancoDoBrasilView />} />
                </Route>
            </Route>


            <Route path="/auth" element={<AuthLayout />} >
                <Route path="sign-in" element={<SignInView />} />
                <Route path="account" element={<Account />} />
                <Route path="coringas" element={<Coringas />} />
                <Route path="verifycode" element={<Verifycode />} />
                <Route path="newpassword" element={<Newpassword />} />
            </Route>

            <Route path="/insc" element={<InscLayout />}>
                <Route path="sign-in" element={<InscView />} />
            </Route>

            <Route path="*" element={<NotFoundPage />} />




        </Routes>

    );
}


function MainRouterElement() {
    return (
        <RequireAuth>
            <AdminLayout>
                <Outlet />
            </AdminLayout>
        </RequireAuth>
    )
}
