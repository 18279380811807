import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './authcontext'
import { ValidateToken, getTokenStorage } from '../services/serviceAuth'



export const RequireAuth = ({ children }) => {
    // const { user } = useAuth()
    const location = useLocation();

    return getTokenStorage() ? children : <Navigate to="/auth/sign-in" state={{ from: location }} replace />;

    // if (!user) {
    //     const token = getTokenStorage();
    //     if (token) {      
    //         ValidateToken(token).then((result) => {
    //             if (result) {                     
    //                 return( result.data.user ? children : <Navigate to="/login/signin" state={{ from: location }} replace /> )                   
    //             }   

    //         });
    //     } else {
    //         return( user ? children : <Navigate to="/login/signin" state={{ from: location }} replace /> )
    //     }


    // } else {
    //     return( user ? children : <Navigate to="/login/signin" state={{ from: location }} replace /> )
    // }






}