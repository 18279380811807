import { Clientes, ClienteDetalhes } from '../views/clientes'
import { DashboardView } from '../views/dashboard'

export const routes = [
	{
		name: 'Dashboard',
		path: '/dashboard',
		element: <DashboardView />
	},
	{
		name: 'Clientes',
		path: '/clientes',
		element: <Clientes />,
	},
	{
		name: "Cliente Detalhes",
		path: "/clientes/:id",
		element: <ClienteDetalhes />
	}
]