import { Icon } from '@chakra-ui/icons'
import { Divider, Flex, Input, InputGroup, InputLeftElement, Stack } from '@chakra-ui/react'
import {
  FiBarChart2,
  FiBookmark,
  FiCheckSquare,
  FiHelpCircle,
  FiHome,
  FiSearch,
  FiSettings,
  FiUsers,
} from 'react-icons/fi'
import { LogoRotta } from '../logo'
import { NavButton } from './NavButton'
import { UserProfile } from '../profile/UserProfile'
import { dataNav } from '../navbar/data'
import { FaUser } from 'react-icons/fa'

export const Sidebar = ({ onClose, user, signout, location, navigate }) => {
  console.log(user)
  return (
    <Flex as="section" minH="100vh" bg="bg-canvas">
      <Flex
        flex="1"
        bg="bg-surface"
        overflowY="auto"
        boxShadow="sm"
        maxW={{
          base: 'full',
          sm: 'xs',
        }}
        py={{
          base: '6',
          sm: '8',
        }}
        px={{
          base: '4',
          sm: '6',
        }}
      >
        <Stack justify="space-between" spacing="1">
          <Stack
            spacing={{
              base: '5',
              sm: '6',
            }}
            shouldWrapChildren
          >
            <LogoRotta />
            
            {/* <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={FiSearch} color="muted" boxSize="5" />
              </InputLeftElement>
              <Input placeholder="Search" />
            </InputGroup> */}

            <Divider />

            <Stack spacing="1">
              {dataNav?.map((item) => (
                <NavButton
                  key={item.path}
                  label={item.label}
                  icon={item.icon ?? FiHome}
                  aria-current={location.pathname.split("/")[1] === item.path.split("/")[1] ? "page" : ""}
                  onClick={() => {
                    navigate(item.path)
                    onClose()
                  }}
                />
              ))}
            </Stack>
          </Stack>

          <Stack
            spacing={{
              base: '5',
              sm: '6',
            }}
          >
            {/* <Stack spacing="1">
              <NavButton label="Help" icon={FiHelpCircle} />
              <NavButton label="Settings" icon={FiSettings} />
            </Stack> */}
            <Divider />
            <UserProfile
              username={user?.nome_usuario}
              icon={<FaUser />}
              nivel={user?.grupo}
              signout={signout}
            />
          </Stack>

        </Stack>
      </Flex>
    </Flex>
  )
}