import React, { useState} from "react";
import { 
  Button, 
  Container, 
  Divider, 
  VStack, 
  Center,
  Box,
  HStack,
  RadioGroup,
  Text,
  Stack, 
  FormControl, 
  Radio} from '@chakra-ui/react'
import { LogoHotline } from '../../../components/logo'
import { ServiceAuth } from '../../../services/serviceAuth';
import { ToastChakra } from '../../../helpers/toast';




export const Coringas = (props) => {
  const auth = ServiceAuth();
  const [coringa, setCoringa] = useState('1');


  const onCickSubmit = async (e) => {    
    e.preventDefault()
    console.log('ola sms: ', coringa)

    try {

      const response = await auth.serviceSendcode(coringa);

      if (!response) {
        ToastChakra('Autenticação', 'Credenciais inválida!', 'error', 3000, 'top-right');            
      } 

      
    } catch (error) {
      ToastChakra('Autenticação', 'Ocorreu um erro ao carregar coringas!!', 'error', 3000, 'top-right');
      
    }
  }  






  return (

    <Container
    maxW="md"
    py={{
      base: '12',
      md: '24',
    }}
  >
    <Stack spacing="8">
        <LogoHotline />
        <Center>
           <Text fontSize='4xl'>Recuperação de conta</Text>            
        </Center>
        <Divider /> 

        <Stack spacing="6">
        
            <Stack spacing="4">
              <FormControl>
                <Center>
                  <Box p={8}>
                  
                    <RadioGroup  onChange={setCoringa} value={coringa}>
                      <Stack>               
                        <Radio value='1'>sms</Radio>
                        <Radio value='2'>email</Radio>
                        <Radio value='3'>whatsapp</Radio>
                      </Stack>
                    </RadioGroup>   

                  </Box>
                  
                </Center>
      
                <Center>
                <Text>Escolha a opção de envio do código de recuperação</Text>
                </Center>
              </FormControl>            
            </Stack>


        </Stack>

        
        <Stack spacing={6}>       
          <Button colorScheme={'blue'} variant={'solid'} onClick= {onCickSubmit} >
            Enviar
          </Button>
         
        </Stack>
                 
    </Stack>
  </Container>


  )
}
