import React, { useState} from "react";
import { 
  Button, 
  Container, 
  Divider, 
  Heading, 
  Center,
  Box,
  HStack,
  PinInputField,
  PinInput,
  Stack, 
  FormControl } from '@chakra-ui/react'
import { LogoHotline } from '../../../components/logo'
import { GoogleIcon } from '../../../components/logo/ProviderIcons'
import { ServiceAuth } from '../../../services/serviceAuth';
import { ToastChakra } from '../../../helpers/toast';



export const Verifycode = (props) => {

  const auth = ServiceAuth();
  const [code, setCode] = useState(null);


  const onCickSubmit = async (e) => {    
    e.preventDefault()
    console.log('ola sms: ', code)

    try {

      const response = await auth.Newpassword(code);

      if (!response) {
        ToastChakra('Autenticação', 'Credenciais inválida!', 'error', 3000, 'top-right');            
      } 

      
    } catch (error) {
      ToastChakra('Autenticação', 'Ocorreu um erro na autenticação do usuario!!', 'error', 3000, 'top-right');
      
    }
  }  






  return (

    <Container
    maxW="md"
    py={{
      base: '12',
      md: '24',
    }}
  >
    <Stack spacing="8">
        <Stack spacing="6" align="center">
            <LogoHotline />
            <Heading
              size={{
                base: 'xs',
                md: 'sm',
              }}
            >
              Log in to your account
            </Heading>
        </Stack>
        <Stack spacing="6">
            <Button variant="secondary" leftIcon={<GoogleIcon boxSize="5" />} iconSpacing="3">
              Continue with Google
            </Button>
            <Divider />
            <Stack spacing="4">
              <FormControl>
                <Center>
                  <Box p={4}>
                    <HStack>
                      <PinInput type="number" onChange={(e) => setCode(e)}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                    </HStack>
                    
                  </Box>
                </Center>
              </FormControl>            
            </Stack>


        </Stack>

        
        <Stack spacing={6}>       
          <Button colorScheme={'blue'} variant={'solid'} onClick= {onCickSubmit} >
            Enviar código
          </Button>
         
        </Stack>
                 
    </Stack>
  </Container>


  )
}
